import { Application } from "@hotwired/stimulus"
const application = Application.start();

import { Dropdown, Modal } from "tailwindcss-stimulus-components"

// // application.register('alert', Alert)
// // application.register('autosave', Autosave)
application.register('dropdown', Dropdown)
application.register('modal', Modal)
// application.register('tabs', Tabs)
// application.register('popover', Popover)
// application.register('toggle', Toggle)
// application.register('slideover', Slideover)

export default class ButtonDropdown extends Modal {

}
